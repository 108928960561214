import { Link } from "gatsby";
import React from "react";

const PartnershipInfor = ({
  title,
  para,
  link,
  linkText,
  logoImage,
  image,
  imageFirst = true,
  className = "",
  paraFirst = false,
}) => {
  return (
    <div className={`${className} partnership-section-wrapper`}>
      <div className="desktop-wrapper">
        {imageFirst ? (
          <div className="partnership-section desktop-wrapper">
            <div className="image-wrapper">
              <img
                src={image}
                alt="HALO Facility Demonstration at HALO Facility"
              />
            </div>
            <div className="content-wrapper">
              <div className="content-section">
                <div className="desktop-wrapper">
                  {logoImage && (
                    <img src={logoImage} alt="HALO partnership Logo" />
                  )}
                  {title && (
                    <h2 className="semi-page-heading fw-600 margin-0">
                      {title}
                    </h2>
                  )}
                </div>
                <div className="mobile-wrapper">
                  {title && (
                    <h2 className="semi-page-heading fw-600 margin-0">
                      {title}
                    </h2>
                  )}
                  {logoImage && (
                    <img src={logoImage} alt="HALO partnership Logo" />
                  )}
                </div>
                {para && (
                  <p
                    className="para"
                    dangerouslySetInnerHTML={{ __html: para }}
                  ></p>
                )}
                {link && linkText && (
                  <Link
                    to={link}
                    className="wipe-btn-black wipe-black lft-rgt-wipe"
                  >
                    {linkText}
                  </Link>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="partnership-section desktop-wrapper">
            <div className="content-wrapper">
              <div className="content-section">
                <div className="desktop-wrapper">
                  {logoImage && (
                    <img src={logoImage} alt="HALO partnership Logo" />
                  )}
                  {title && (
                    <h2 className="semi-page-heading fw-600 margin-0">
                      {title}
                    </h2>
                  )}
                </div>
                <div className="mobile-wrapper">
                  {title && (
                    <h2 className="semi-page-heading fw-600 margin-0">
                      {title}
                    </h2>
                  )}
                  {logoImage && (
                    <img src={logoImage} alt="HALO partnership Logo" />
                  )}
                </div>
                {para && (
                  <p
                    className="para margin-0"
                    dangerouslySetInnerHTML={{ __html: para }}
                  ></p>
                )}
                {link && linkText && (
                  <Link
                    to={link}
                    className="wipe-btn-black wipe-black lft-rgt-wipe"
                  >
                    {linkText}
                  </Link>
                )}
              </div>
            </div>
            <div className="image-wrapper">
              <img
                src={image}
                alt="HALO Facility Demonstration at HALO Facility"
              />
            </div>
          </div>
        )}
      </div>
      <div className="mobile-wrapper">
        <div className="content-wrapper">
          {logoImage && <img src={logoImage} alt="HALO partnership Logo" />}

          {title && (
            <h2 className="semi-page-heading fw-600 margin-0">{title}</h2>
          )}
        </div>
        {paraFirst ? (
          <div className="partnership-section desktop-wrapper">
            <div className="content-wrapper">
              <div className="content-section">
                {para && (
                  <p
                    className="para"
                    dangerouslySetInnerHTML={{ __html: para }}
                  ></p>
                )}
                {link && linkText && (
                  <Link
                    to={link}
                    className="wipe-btn-black wipe-black lft-rgt-wipe"
                  >
                    {linkText}
                  </Link>
                )}
              </div>
            </div>
            <div className="image-wrapper">
              <img
                src={image}
                alt="HALO Facility Demonstration at HALO Facility"
              />
            </div>
          </div>
        ) : (
          <div className="partnership-section desktop-wrapper">
            <div className="image-wrapper">
              <img
                src={image}
                alt="HALO Facility Demonstration at HALO Facility"
              />
            </div>
            <div className="content-wrapper">
              <div className="content-section">
                {para && (
                  <p
                    className="para"
                    dangerouslySetInnerHTML={{ __html: para }}
                  ></p>
                )}
                {link && linkText && (
                  <Link
                    to={link}
                    className="wipe-btn-black wipe-black lft-rgt-wipe"
                  >
                    {linkText}
                  </Link>
                )}
              </div>
            </div>
          </div>
        )}
        {/* <div className="partnership-section desktop-wrapper">
          <div className="image-wrapper">
            <img
              src={image}
              alt="HALO Facility Demonstration at HALO Facility"
            />
          </div>
          <div className="content-wrapper">
            <div className="content-section">
              {para && (
                <p
                  className="para"
                  dangerouslySetInnerHTML={{ __html: para }}
                ></p>
              )}
              {link && linkText && (
                <Link
                  to={link}
                  className="wipe-btn-black wipe-black lft-rgt-wipe"
                >
                  {linkText}
                </Link>
              )}
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default PartnershipInfor;
