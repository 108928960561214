import React from "react";

const PartnerHeader = ({ title, para, image, mobImag }) => {
  return (
    <div className="partnership__header-wrapper">
      <div className="content-wrapper">
        <h1 className="page-heading margin-0 titlecase-text">{title}</h1>
        <p className="para margin-0">{para}</p>
      </div>
      <div className="image-wrapper">
        <img
          src={image}
          alt="Goken's partnership with HALO"
          className="desktop-wrapper"
        />
        <img
          src={mobImag ? mobImag : image}
          alt="Goken's partnership with HALO image"
          className="mobile-wrapper"
        />
      </div>
    </div>
  );
};

export default PartnerHeader;
