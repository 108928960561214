import React, { useEffect, useState } from "react";

const PartnerQuote = ({ quotes = [] }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [stopScroll, setStopScroll] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!stopScroll) {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % quotes.length);
      }
    }, 5000); // 3 seconds pause + 0.5 seconds animation

    return () => clearInterval(interval);
  }, [stopScroll, currentIndex]);
  return (
    <div
      className="partnership__quote-wrapper quotes-slider"
      onMouseEnter={() => {
        setStopScroll(true);
      }}
      onMouseLeave={() => {
        setStopScroll(false);
      }}
    >
      {quotes?.map(
        ({ leftImage, rightImage, text, textBy, nameClass }, index) => {
          return (
            <div
              className="content-section-wrapper quote"
              key={`${index}-${textBy}`}
              style={{
                opacity: currentIndex === index ? 1 : 0,
                display: currentIndex === index ? "flex" : "none",
              }}
            >
              <div className="left-image-wrapper">
                <img src={leftImage} alt="left quote image" />
              </div>
              <div className="content-wrapper">
                <p className="title fw-400"> {text}</p>
                <p className={`title fw-400 ${nameClass}`}>{textBy}</p>
              </div>
              <div className="right-image-wrapper">
                <img src={rightImage} alt="right quote image" />
              </div>
            </div>
          );
        }
      )}
      <div className="qoute-dots-wrapper">
        <div
          className={`dot ${currentIndex == 0 ? "active  color-en" : ""}`}
          onClick={() => {
            setCurrentIndex(0);
          }}
        ></div>
        <div
          className={`dot ${currentIndex == 1 ? "active color-ja" : ""} `}
          onClick={() => {
            setCurrentIndex(1);
          }}
        ></div>
      </div>
    </div>
  );
};

export default PartnerQuote;
