import { Link } from "gatsby";
import React from "react";

const WorkwithUs = ({ title, para, link, linkText }) => {
  return (
    <div className="partnership__cta margin-3rem">
      <h2 className="page-heading margin-0">{title}</h2>
      <p
        className="semi-title fw-400  margin-0"
        dangerouslySetInnerHTML={{ __html: para }}
      ></p>
      <Link className="reachout-btn primary-btn bg-en" to={link}>
        {linkText}
      </Link>
    </div>
  );
};

export default WorkwithUs;
